.tandc__body{
    position: relative;
    width:80%;
    left: 10%;
    background-color: white;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    height: 120vh;
}

.tandc__container{
    position: relative;
    top: 5%;
    width: 60%;
    left: 20%;
}

p{
    margin-left: 15px;
}

.tandc__container__text__title{
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: 500;
    text-decoration: underline;
    margin-left: 0;
}

.tandc__div{
    margin-bottom: 5px;
}