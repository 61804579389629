
.yoursummary__graph__container{
    position: relative;
    width:80%;
    left: 10%;
    top: 2%;
    background-color: white;
    height: auto;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    justify-content: center;

}

.panel__container{
    position: relative;
    margin-top: 2%;
    width:75%;
    left: 12.5%;
    display: flex;
    flex-direction: column;
}

.infographic__container{
    position: relative;
    width:60;
    left: 0%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.infographic__container__parent__column{
    position: relative;
    left: 0%;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
}

.infographic__container__parent__row{
    position: relative;
    left: 0%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.infographic__bar__container{
    position: relative;
    width:100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}


.title__container{
    position: relative;
    padding: 5px;
    margin-top: 20px;
    width:70%;
    left: 15%;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.title__container a{
    outline: none;
    text-decoration: none;
    font-size: 16px;
    display: inline-block;
    color: #af292e;
    text-align: left;
    border-bottom: 2px solid transparent;
    width:auto;
    margin-bottom: auto;
    margin-left:auto;
    margin-right: auto;
}

.title__container a:active{
    color: inherit;
    text-decoration: none;
}
  
.title__container a:hover{
    border-bottom: 2px #af292e solid;
}

.title__notice{
    font-weight: bold;
    font-style: italic;
    margin-top: 5px;
}

h2{
    margin-top: 2%;
    text-align: center;
}

h3{
    text-align: center;
    margin-bottom: 1%;
}

h4{
    margin-bottom: 2px;
}

p{
    margin-bottom: 5px;
    margin-top: 2px;
}
.slider__container__summary{
    position: relative;
    padding: 5px;
    margin-top: 20px;
    margin-right: 20px;
    max-width:300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.description__container{
    position: relative;
    padding: 5px;
    margin-top: 20px;
    width:70%;
    left: 15%;
    border: solid rgb(114, 114, 114) 1px;
    padding: 1%;
}

.button__container{
    width: 100%;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.description__header{
    margin-bottom: 10px;
    text-align: center;
}

.description__payments{
    margin: 10px;
}

.radio__container__summary{
    position: relative;
    width: 100%;

    margin-top: 5px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 30px;

}

.infographic__icon{
    margin-right: 30px;
};

.scenario__container{
    position: relative;
    padding: 5px;
    margin-top: 20px;
    width:70%;
    left: 15%;
    border: solid rgb(255, 0, 0) 1px;
    padding: 1%;
}

#switch__infobutton{
    margin-left: 3px;
    margin-top: 1px;
    width: 15px;
    height: 15px;
}